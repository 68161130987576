import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: window.__ENV__.REACT_APP_AZURE_AD_CLIENT_ID || process.env.REACT_APP_AZURE_AD_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${window.__ENV__.REACT_APP_AZURE_AD_TENANT_ID || process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: window.__ENV__.REACT_APP_AZURE_REDIRECT_URI || process.env.REACT_APP_AZURE_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  }
};

export const loginRequest = {
 scopes: ["User.Read"]
};

const msalInstance = new PublicClientApplication(msalConfig);

export { msalInstance }
